import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { ADCUBE_ENTITY } from "../../../../common/const/ADcubeEntity";
import { USER_ROLE_TYPE } from "../../../../common/const/UserRoleType";
import { UserRole } from "../../../../components/UserRole";
import { ApiService } from "../../../../service/ApiService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ChangeUserRoleModal = ({
  open,
  onClose,
  user,
  entityType,
  entityId,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [newRole, setNewRole] = useState(null);

  const updateUserRoleApi = () => {
    setDisabled(true);

    if (entityType === ADCUBE_ENTITY.COMPANY) {
      ApiService.updateUserCompanyRole(user.user_id, entityId, newRole);
    } else if (entityType === ADCUBE_ENTITY.ADVERTISER) {
      ApiService.updateUserAdvertiserRole(user.user_id, entityId, newRole);
    }
    setTimeout(() => {
      window.location.reload(false);
      onClose(true);
    }, 1500);
  };

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Update{" "}
          {entityType === ADCUBE_ENTITY.COMPANY ? "company" : "advertiser"} user
          role
        </Typography>
        <Box paddingX={0} paddingY={1}>
          {Object.keys(USER_ROLE_TYPE)
            .filter((r) => r !== user.role)
            .map((role) => (
              <Box
                sx={{ margin: "1rem", padding: "0.5rem", textAlign: "center" }}
                key={role}
              >
                <UserRole
                  role={role}
                  style={{ cursor: "pointer" }}
                  enabled={role === newRole}
                  onClick={() => setNewRole(role)}
                />
              </Box>
            ))}
        </Box>
        <Box style={{ textAlign: "center" }}>
          <Button
            onClick={updateUserRoleApi}
            variant="contained"
            disabled={disabled || newRole == null}
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
